/**
 * Created by jimmy on 2019/11/8.
 */

export const env = window.location.host.indexOf('yeekin.cn') > -1 ? 'product' : 'dev';

// export const apiPrefix = 'http://api.yeekin.cn/v1';
export const apiPrefix = '/api';
// export const imgPrefix = env == 'product' ? '/' : 'http://127.0.0.1:44999/';
export const imgPrefix = 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/zhujiajiao/images/';

export const appId = env == 'product' ? 'wx90c5ec3839eba92a' : 'wx6ef0bb6a5b9e3955';

export const regexpPattern = {
  mobile: /^1[3456789]\d{9}$/,
};
