/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as config from '../../common/config';
import Page from '../../components/page';

import './index.less';
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

const PageModel = props => {
	const history = useHistory();

	const [ stock, setStock ] = useState(-1);
	const [ data, setData ] = useState({});
	const [ dataLoad, setDataLoad ] = useState(false);
	const { id } = useParams();
	const getData = async () => {
		const result = await Request.post({
			url: '/app/product/info',
			data: { id }
		});
		setDataLoad(true);
		if(!result) return;

		const data = result.content;
		if(data?.multiple && typeof data.multiple == 'string') {
			data.multiple = JSON.parse(data.multiple);
		}
		const descriptions = data?.description.split("&");
		data.serviceDesc = descriptions[0]?descriptions[0]:'使用后或者过期无法退款';
		data.explainDesc = descriptions[1]?descriptions[1]:`购票后${data?.validityDays}日内使用，逾期作废，此票为一次性使用，使用当日有效`;
		data.hasDate = data?.specifications?.dateList?.length > 0;
		data.hasPrice = data?.specifications?.priceList?.length > 0;
		data.hasTime = data?.specifications?.timeList?.length > 0;
		data.hasItem = data.hasDate || data.hasPrice || data.hasTime;
		if(!data.hasItem) {
			setStock(999);	// 没有规格的默认库存999
		}

		setData(data);
	};
	useEffect(() => {
		getData();

		return () => false;
	}, [123]);

	const [ userInfo, setUserInfo ] = useState(null)
	const getUserInfo = () => {
		const _userInfo = Utils.user.getInfo();
		setUserInfo(_userInfo);
	};
	useEffect(() => {
		getUserInfo();

		return () => false;
	}, [123]);

	const [ showItemSelection, setShowItemSelection ] = useState(false);
	const [ orderForm, setOrderForm ] = useState({
		num: 1,
		phone: ''
	});
	const [ orderSku, setOrderSku ] = useState([]);
	const _orderVerify = () => {
		/**
		 * 场景ID，不同场景
		 * @type {string}
		 */
		// const sceneId = Utils.sceneHelper.get();
		// if(!sceneId) {
		// 	Utils._alert('参数缺失，请重新扫码');
		// 	return false;
		// }

		/**
		 * 多规格的时候，规格选择校验
		 */
		if(data.hasDate && (!orderForm.appointmentDate || !orderForm.dateSpecId)) {
			Utils._alert('请选择日期');
			return false;
		}
		if(data.hasPrice && (!orderForm.packageName || !orderForm.priceSpecId)) {
			Utils._alert('请选择套餐');
			return false;
		}
		if(data.hasTime && !orderForm.timeSpecId) {
			Utils._alert('请选择时间段');
			return false;
		}

		/**
		 * 验证手机号
		 */
		if(!config.regexpPattern.mobile.test(orderForm.phone)) {
			Utils._alert('请输入合法的手机号码');
			return false;
		}

		return true;
	};
	const submitOrderHandle = async () => {
		if(!_orderVerify()) return;

		const sceneId = Utils.sceneHelper.get();
		const submitObj = {
			items: [
				{
					price: data?.price,
					productId: data?.id,
					...orderForm,
					sku: orderSku?.length > 0 ? orderSku.join(',') : ''
				}
			],
			openId: userInfo?.openId,
			sceneId: Number(sceneId),
			userId: userInfo?.id
		};
		console.log('submitObj', submitObj);

		const result = await Request.post({
			url: '/app/order/create',
			data: submitObj
		});
		if(!result) return;

		Utils._alert('下单成功');

		// 支付
		Utils.iwx.pay(result?.content || {}, {
			success: () => {
				Utils._alert('支付成功');
				setTimeout(() => {
					history.push('/orders');
				}, 1000);
			}
		});
	};

	const syncSkuInfo = async (options = {}) => {
		const { dateSpecId, dateString, priceSpecId, timeSpecId } = options;
		if(!(dateSpecId || dateString || priceSpecId || timeSpecId)) return;

		const result = await Request.post({
			hideLoading: true,
			url: '/app/verification/getInventory',
			data: {
				productId: id,
				dateSpecId, dateString,
				priceSpecId,
				timeSpecId
			}
		});
		if(!result) return;

		setStock(result.content);
	};
	useEffect(() => {
		if(orderForm.checkInventory){
			syncSkuInfo(orderForm);
		}
	}, [ orderForm ]);

	const _getPriceFromSpec = (priceSpecId) => {
		const [ target ] = data?.specifications?.priceList.filter(item => item.id == priceSpecId);
		return target?.price;
	}

	const _timeCompare = (targetTime, timeFormat = 'HH:mm') => {
		const current = moment().format(timeFormat);
		if(targetTime < current) return -1;
		if(targetTime == current) return 0;
		if(targetTime > current) return 1;
	}

	return (
		<Page {...{ pageid: 'product-detail', ...props, title: data?.title }}>
			<section className="product-detail-container">
				<Carousel {...{
					className: 'carousel1',
					style: { height: window.innerWidth*0.88 },
					showArrows: false,
					autoPlay: true,
					showIndicators: true,
					infiniteLoop: true,
					showStatus: false
				}}>
					{
						data?.multiple?.length > 0 && data.multiple.map((item, idx) => {
							return (
								<div className="carousel1-item" style={{
									height: window.innerWidth*0.88,
									backgroundImage: `url(${config.imgPrefix}${item})`
								}} key={idx} />
							)
						})
					}
				</Carousel>
				<div className="baseinfo">
					<div className="title">{data.title}</div>
					<div className="price-wrap">
						<span className="price"><em>¥</em>{data.price}</span>
						{ data.highPrice && <span className="price"><i>-</i><em>¥</em>{data.highPrice}</span> || '' }
					</div>
				</div>
				<div className="descinfo">
					{
						[
							{ label: '服务', text: data?.serviceDesc || '' },
							{ label: '说明', text: data?.explainDesc || '' },
							{ label: '描述', text: data?.explain || '-' },
						].map((item, idx) => {
							return (
								<div className="item" key={idx}>
									<label>{item.label}</label>
									<span>{item.text}</span>
								</div>
							)
						})
					}
				</div>
				<div className="detail-info">
					<div dangerouslySetInnerHTML={{ __html: data.detail }} />
				</div>

				<div className="footer">
					{
						dataLoad && data && data.status === 1 &&
						<div className="buy-button" onClick={() => {
							setShowItemSelection(true);
						}}>立即购买</div>
					}
					{ dataLoad && data.status === 0 && <div className="buy-button disabled">已下架</div> }
					{ dataLoad && data.status === 2 && <div className="buy-button disabled">敬请期待</div> }
				</div>
			</section>

			{
				showItemSelection && <section className="items-mask-wrap">
					<div className="items-wrap">
						<div className="items-info">
							<div className="product-info">
								<div className="cover" style={{ backgroundImage: `url(${config.imgPrefix}${data.picture})` }} />
								<div className="message">
									<div className="price"><em>¥</em>{data.hasPrice && _getPriceFromSpec(orderForm.priceSpecId) || data.price}</div>
									{ data.hasItem && stock != -1 && <div className="stock">剩余<span>{stock}</span>件</div> || null }
									{ data.hasItem && <div className="tip">请选择日期 套餐</div> }
									<em className="close iconfont icon-close" onClick={() => {
										setShowItemSelection(false);
									}} />
								</div>
							</div>
							{
								data.hasItem && <>
									{
										data.hasDate && <div className="item-info-wrap">
											<div className="item-title">选择日期</div>
											<div className="item-info">
												{
													data.specifications.dateList.map((item, idx) => {
														let cls = [ 'item' ];
														if(orderForm.appointmentDate == item.value) {
															cls.push('selected');
														}

														const disabled = (() => {
															let flag = false;

															if(data.hasTime) {
																const lastTime = data.specifications.timeList[data.specifications.timeList.length - 1];
																const targetTime = lastTime?.value?.split('-');
																if(targetTime && targetTime[1]) {
																	if(_timeCompare(targetTime[1], 'HH:mm') == -1 && _timeCompare(item.value, 'YYYY-MM-DD') == 0){
																		flag = true;
																	}
																}
															}

															return flag;
														})();
														if(disabled) {
															cls.push('disabled');
														}

														return <div className={cls.join(' ')} key={idx} onClick={() => {
															if(disabled) return;

															setOrderForm({
																...orderForm,
																dateSpecId: item.id,
																appointmentDate: item.value,
																dateString: item.value,
																checkInventory: true
															});

															orderSku[0] = item.value;
															setOrderSku(orderSku);
														}}>{item.value}</div>
													})
												}
											</div>
										</div>
									}
									{
										data.hasTime && <div className="item-info-wrap">
											<div className="item-title">选择时间段</div>
											<div className="item-info">
												{
													data.specifications.timeList.map((item, idx) => {
														let cls = [ 'item' ];
														if(orderForm.timeSpecId == item.id) {
															cls.push('selected');
														}
														const disabled = (() => {
															let flag = false;
															if(!orderForm?.appointmentDate) {
																return true
															}

															const targetTime = item?.value?.split('-');
															if(targetTime && targetTime[1]) {
																if(_timeCompare(targetTime[1], 'HH:mm') == -1 && _timeCompare(orderForm.appointmentDate, 'YYYY-MM-DD') == 0){
																	flag = true;
																}
															}

															return flag;
														})();
														if(disabled) {
															cls.push('disabled');
														}

														return <div className={cls.join(' ')} key={idx} onClick={() => {
															if(disabled) return;

															setOrderForm({
																...orderForm,
																timeSpecId: item.id,
																checkInventory: true
															})

															orderSku[1] = item.value;
															setOrderSku(orderSku);
														}}>{item.value}</div>
													})
												}
											</div>
										</div>
									}
									{
										data.hasPrice && <div className="item-info-wrap">
											<div className="item-title">选择套餐</div>
											<div className="item-info">
												{
													data.specifications.priceList.map((item, idx) => {
														const cls = [ 'item' ];
														if(orderForm.priceSpecId == item.id) {
															cls.push('selected');
														}

														const disabled = (() => {
															let flag = false;

															// if(stock <= 0) {
															// 	flag = true;
															// }

															return flag;
														})();
														if(disabled) {
															cls.push('disabled');
														}

														return <div className={cls.join(' ')} key={idx} onClick={() => {
															if(disabled) return;

															setOrderForm({
																...orderForm,
																packageName: item.name,
																price: item.price,
																priceSpecId: item.id,
																checkInventory: true
															});

															orderSku[2] = item.value;
															setOrderSku(orderSku);
														}}>{item.value}</div>
													})
												}
											</div>
										</div>
									}
								</>
							}
							<div className="book-info">
								<div className="item">
									<label>购买数量</label>
									<div className="counter">
										<em className="iconfont icon-minus" onClick={() => {
											setOrderForm({
												...orderForm,
												num: Math.max(orderForm.num - 1, 1),
												checkInventory: false
											})
										}} />
										<i>{orderForm?.num || 0}</i>
										<em className="iconfont icon-plus" onClick={() => {
											if(orderForm.num == stock) {
												Utils._alert(`剩余${stock}份`);
											}
											setOrderForm({
												...orderForm,
												num: Math.min(stock, orderForm.num + 1),
												checkInventory: false
											});
										}} />
									</div>
								</div>
								<div className="item"><label>手机号码</label><input maxLength={11} placeholder="请输入有效手机号码" onChange={(e) => {
									setOrderForm({
										...orderForm,
										phone: e.currentTarget.value,
										checkInventory: false
									})
								}} /></div>
							</div>
						</div>
						<div className="items-footer">
							<div className="buy-button" onClick={submitOrderHandle}>立即购买</div>
						</div>
					</div>
				</section>
			}
		</Page>
	);
};

export default PageModel;
