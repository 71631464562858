/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import * as Request from './request';
import * as CONST from './config';

export const getSearchByKey = (key, search = window.location.search) => {
	if(!search) return '';

	const _search = search.slice(1);
	const _searchArr = _search.split('&');
	const _map = {};
	_searchArr.map(item => {
		const _item = item.split('=');
		if(_item[0] && _item[1]) {
			_map[_item[0]] = _item[1];
		}
	});
	return _map[key] || '';
};

export const setTitle = (title = '') => {
	if(document) {
		document.title = title;
	}
};

// loading
export const loading = {
	show: () => {
		document.getElementById('loading').style.display = 'block';
	},
	hide: () => {
		document.getElementById('loading').style.display = 'none';
	}
};

// toast
export const toast = {
	show: (txt) => {
		const $toast = document.getElementById('toast');
		$toast.style.display = 'block';
		$toast.innerText = txt;
	},
	hide: () => {
		const $toast = document.getElementById('toast');
		$toast.style.display = 'none';
		$toast.innerText = '';
	}
};

export const getUA = () => {
	if(!window || !window.navigator || !window.navigator.userAgent){
		return null;
	}

	const ua = window.navigator.userAgent.toLocaleLowerCase();

	return {
		ua,
		isInWechat: ua.indexOf('micromessenger') > -1,
		isInRongMeiApp: ua.indexOf('rongmeiapp/qingpu') > -1 || ua.indexOf('rongmeiapp') > -1,    // mainly for QingPu
    isIOS: ua.indexOf("iphone") > -1 || ua.indexOf("ios") > -1,
    isAndroid: ua.indexOf("android") > -1 || ua.indexOf("linux") > -1
	}
};

export const _alert = (txt) => {
	if(!txt) return;

	toast.show(txt);

	setTimeout(() => {
		toast.hide();
	}, 2000);
};

/**
 * 登录功能
 * 微信内：微信登录
 * APP 内：暂时先 return
 */
const _user = {
	getInfo: () => {
		const userInfoStr = window.sessionStorage.getItem('_user');
		return userInfoStr && typeof userInfoStr == 'string' ? JSON.parse(userInfoStr) : userInfoStr;
	},
	setInfo: (info = {}) => {
		window.sessionStorage.setItem('_user', JSON.stringify(info));
	},
	login: async () => {

	},
	isLogin: () => {
		const userInfo = _user.getInfo();
		return userInfo && userInfo.id;
	},
	_wxRedirectUriReset: (uri = window.location.href)=>{
		let hash, pureUri, pureUrl;
		hash = uri.split('#')[1];
		pureUri = uri.split('#')[0];
		pureUrl = pureUri.split('?')[0];

		let querys = (function (querys) {
			if(!querys) return {};
			let query = {};
			let _querysArr = querys.split('&');
			for(let i=0, len=_querysArr.length; i<len; i++){
				let qItem = _querysArr[i].split('=');
				query[qItem[0]] = qItem[1];
			}
			return query;
		})(pureUri.split('?')[1]);

		let queryArr = [];
		for(let i in querys){
			if(querys[i] && i != 'code' && i != 'state'){
				queryArr.push(i + '=' + querys[i]);
			}
		}
		if(queryArr.length > 0){
			pureUrl += '?' + queryArr.join('&')
		}
		if(!!hash) pureUrl += '#' + hash;

		return pureUrl;
	},
	wxLogin: async (option = {}, callback) => {
		const _userInfo = _user.getInfo();
		console.log('_userInfo wxlogin', _userInfo);
		if(_userInfo && _userInfo.nickname) return;

		const code = getSearchByKey('code');
		const state = getSearchByKey('state');
		if(!code) {
			const url = `https://open.weixin.qq.com/connect/oauth2/authorize?${[
				`appid=${CONST.appId}`,
				`redirect_uri=${encodeURIComponent(_user._wxRedirectUriReset(window.location.href))}`,
				`response_type=code`,
				`scope=snsapi_base`,
				`state=STATE_BASE`
			].join('&')}#wechat_redirect`;
			console.log('url', url);
			window.location.replace(url);
			return;
		}

		console.log("{ code, scope: ({ 'STATE_BASE': 'snsapi_base', 'STATE_USERINFO': 'snsapi_userinfo' })[state] }", { code, scope: ({ 'STATE_BASE': 'snsapi_base', 'STATE_USERINFO': 'snsapi_userinfo' })[state] })
		// return;
		const result = await Request.post({
			hideError: true,
			url: '/app/wx/login',
			data: { code, scope: ({ 'STATE_BASE': 'snsapi_base', 'STATE_USERINFO': 'snsapi_userinfo' })[state] }
		});
		console.log('result', result);
		if(!result.success) return;
		console.log('result content', result.content, result.content.wxUser);

		if(result?.content?.wxUser && !result.content.wxUser.nickname) {
			console.log('youjinlai', JSON.stringify(result));
			const url = `https://open.weixin.qq.com/connect/oauth2/authorize?${[
				`appid=${CONST.appId}`,
				`redirect_uri=${encodeURIComponent(_user._wxRedirectUriReset(window.location.href))}`,
				`response_type=code`,
				`scope=snsapi_userinfo`,
				`state=STATE_USERINFO`
			].join('&')}#wechat_redirect`;
			window.location.replace(url);
			return;
		}

		_user.setInfo(result.content.wxUser);
		setTimeout(() => {
			callback && callback();
		}, 666);
	},
	wxsign: async () => {
		const result = await Request.get({
			url: '/user/wxsign',
			data: {
				url: encodeURIComponent(window.location.href.split('#')[0])
			}
		});
		console.log('wxsign result', result);
		if(!result) return;

		window.wx.config({
			// debug: true,
			...result.data,
			jsApiList: [
				'startRecord',
				'stopRecord',
				'onVoiceRecordEnd',
				'playVoice',
				'pauseVoice',
				'stopVoice',
				'onVoicePlayEnd',
				'uploadVoice',
			] // 必填，需要使用的JS接口列表
		})
	}
};
export const user = _user;

export const iwx = {
	pay(config, extra = {}) {
		config.package = config.package || config.packageStr;
		if(config.h5_url) {
			window.location.href = `${config.h5_url}?redirect_url=${window.location.origin}/order/list`;
		}else{
			window.WeixinJSBridge.invoke('getBrandWCPayRequest', config, res => {
				if (res.err_msg === 'get_brand_wcpay_request:ok') {
					if (extra?.success) {
						extra.success();
					}
					return ;
				} else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
					// 取消支付
					console.log('取消支付');

				} else if (res.err_msg === 'get_brand_wcpay_request:fail') {
					// 支付失败
					alert('支付失败，请稍后重试~');
				} else {
					// 极端情况
					alert('支付失败，请稍后重试~');
				}

				if (extra?.error) {
					extra.error();
				}
			});
		}
	}
}

export const arrayToMap = (arr = [], key = 'id', value = 'label') => {
	const map = {};
	arr.map(a => {
		map[a[key]] = a[value];
	});
	return map;
};

export const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
};

export const uploadHelper = {
	submitHandle: (fileList) => {
		const _fileList = [];
		fileList.map(fileInfo => {
			if(fileInfo.response && fileInfo.response.ok == 0 && fileInfo.response.data && fileInfo.response.data.src) {
				_fileList.push(fileInfo.response.data.src);
			}else if(fileInfo.src) {
				_fileList.push(fileInfo.src);
			}
		});
		return JSON.stringify(_fileList);
	},
	editDisplayHandle: (fileListStr) => {
		const _fileList = [];
		const _fileListStr = JSON.parse(fileListStr);
		if(_fileListStr && _fileListStr.length > 0) {
			_fileListStr.map((file, idx) => {
				const fileInfo = file.split('/') && file.split('/')[2] || '';
				if(fileInfo) {
					const [ name, suffix ] = fileInfo.split('.');
					const [ fileName ] = name.split('-');
					_fileList.push({
						uid: idx,
						name: fileName,
						status: 'done',
						url: CONST.imgPrefix + file,
						src: file
					});
				}
			})
		}
		return _fileList;
	}
};

export const niceStr = str => {
	if(!str) return '';
	str = str.replace(/[0-9]+-/ig, '').replace(/\.jpg/ig, '').replace(/\.png/ig, '');
	return str;
};

export const beautyTableColunm = (conf = {}) => {
	const childrenRender = conf.children || null;
	delete conf.children;

	return {
		width: 420,
		render: (text, record, index) => {
			const style = { maxHeight: 88, overflow: 'auto' }
			if(childrenRender && typeof childrenRender == 'function') {
				return (
					<div style={style}>{ childrenRender(record)}</div>
				)
			}else{
				text = text ? text.replace(/\n/ig, '<br />') : text;
				return (
					<div style={style} dangerouslySetInnerHTML={{ __html: text }} />
				)
			}
		},
		...conf
	}
};

export const sceneHelper = {
	set: () => {
		const scene = getSearchByKey('sid');
		if(scene) {
			window.sessionStorage.setItem('scene', scene);
		}
	},
	get: () => {
		return window.sessionStorage.getItem('scene');
	},
};
