/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as config from '../../common/config';
import Page from '../../components/page';

import './index.less';
import {useHistory} from "react-router-dom";
import {imgPrefix} from "../../common/config";

const PageHomeModel = props => {
	const history = useHistory();

	const [ list, setList ] = useState([]);
	const getList = async () => {
		const result = await Request.post({
			url: '/app/product/list',
			data: {}
		});
		if(!result) return;

		setList(result.content);
	};
	useEffect(() => {
		getList();

		return () => false;
	}, [123]);

	console.log('list', list);

	return (
		<Page {...{ pageid: 'home', showFooter: true, ...props }}>
			<section className="home-container">
				<div className="base-banner">欢迎来到朱家角古镇</div>
				<div className="product-list">
					{
						list?.length > 0 && list.map((item, idx) => {
							return (
								<div className="product-item" key={idx} onClick={() => {
									history.push(`/product/${item.id}`);
								}}>
									<div className="cover" style={{ backgroundImage: `url(${config.imgPrefix}${item.imagePath})` }} />
									<div className="message">
										<div className="title">{item.title}</div>
										<div className="footer">
											<div className="price-wrap">
												售价：<span className="price"><em>¥</em>{item.price}</span>
												{ item.highPrice && <span className="price"><i style={{ paddingLeft: 4, paddingRight: 4 }}>-</i><em>¥</em>{item.highPrice}</span> || '' }
											</div>
											<div className="buy-button">立即购买</div>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</section>
		</Page>
	);
};

export default PageHomeModel;
