/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import QRCode from 'qrcode.react';

import * as Request from '../../common/request';
import * as config from '../../common/config';
import * as Utils from '../../common/utils';
import Page from '../../components/page';

import './index.less';

let timer = null;

const PageModel = props => {
	if(timer) {
		clearTimeout(timer);
		timer = null;
	}

	const [ listStatus, setListStatus ] = useState(0);
	const [ list, setList ] = useState();
	const getList = async () => {
		const userInfo = Utils.user.getInfo();
		if(!userInfo?.openId) {
			timer = setTimeout(() => {
				getList();
			}, 222);
			return;
		}
		const result = await Request.post({
			url: '/app/order/list',
			data: {
				orderStatus: listStatus || 0,
				openId: userInfo?.openId
			}
		});
		if(!result) return;

		setList(result.content?.list || []);
	};
	useEffect(() => {
		getList();
	}, [ listStatus ]);

	// 退款
	const refundHandle = async (orderId) => {
		if(!window.confirm('确认申请退款？')) {
			console.log('bu tui')
			return;
		}
		console.log('tui');
		const _userInfo = Utils.user.getInfo();
		const result = await Request.post({
			url: '/app/order/refund',
			data: {
				orderId,
				openId: _userInfo?.openId
			}
		});
		if(!result) return;
		Utils._alert('操作成功');
		getList();
	};

	// 使用
	const [ targetOrder, setTargetOrder ] = useState(null);
	const [ targetOrderFlag, setTargetOrderFlag ] = useState(false);

	return (
		<Page pageid="order" title="我的订单" showFooter={true}>
			<section className="order-list-container">
				<div className="order-tab">
					{
						[
							{ label: '全部', status: 0 },
							{ label: '待付款', status: 1 },
							{ label: '已付款', status: 2 },
							{ label: '退款', status: 3 },
						].map((item, idx) => {
							return <div className={`tab-item${item.status == listStatus ? ' current' : ''}`} key={idx} onClick={() => {
								setListStatus(item.status);
							}}>{item.label}</div>
						})
					}
				</div>
				{
					list && list.length > 0 && list.map((item, idx) => {
						const productInfo = item.productItems && item.productItems[0] || {};
						let total = (() => {
							let t = 0;
							if(item.productItems?.length > 0) {
								item.productItems.map(_item => {
									t += _item.num;
								})
							}
							return t;
						})();
						return (
							<div className="list-item" key={idx}>
								<div className="order-number">订单编号：{item.orderNumber}</div>
								<div className="product-info">
									<div className="cover" style={{ backgroundImage: `url(${config.imgPrefix}${productInfo.picture})` }} />
									<div className="message">
										<div className="name">{productInfo.productName}</div>
										<div className="price-wrap">
											<span className="price"><em>¥</em>{productInfo.price}</span>
											<span className="count">x{total}</span>
										</div>
									</div>
								</div>
								<div className="total-price"><em>¥</em>{item.totalAmount}</div>
								<div className="order-ctrl">
									<div className="status">订单状态：{item.orderStatusTxt}</div>
									{
										item.payStatus == 1 && (
											(
												item.orderStatus == 0 && <div className="order-btns-wrap">
													<div className="status-button default" onClick={(e) => {
														e.stopPropagation();
														console.log('item', item);
														refundHandle(item.orderNumber);
													}}>申请退款</div>
													<div className="status-button primary" onClick={(e) => {
														e.stopPropagation();

														const total = (() => {
															let t = 0;
															if(item.productItems?.length > 0) {
																item.productItems.map(_item => {
																	t += _item.num;
																})
															}
															return t;
														})();
														setTargetOrder({
															...item,
															total,
															productInfo: item?.productItems && item?.productItems[0] || {}
														});
														setTargetOrderFlag(true);
													}}>立即使用</div>
												</div>
											) ||
											(
												item.orderStatus == 1 && <div className="order-btns-wrap">
													<div className="status-button primary" onClick={(e) => {
														e.stopPropagation();

														const total = (() => {
															let t = 0;
															if(item.productItems?.length > 0) {
																item.productItems.map(_item => {
																	t += _item.num;
																})
															}
															return t;
														})();
														setTargetOrder({
															...item,
															total,
															productInfo: item?.productItems && item?.productItems[0] || {}
														});
														setTargetOrderFlag(true);
													}}>立即使用</div>
												</div>
											)
										)
									}
								</div>
							</div>
						)
					})
				}
			</section>

			{
				targetOrderFlag && <section className="order-detail-container">
					<section className="order-detail">
						<div className="product-info">
							<div className="cover" style={{ backgroundImage: `url(${config.imgPrefix}${targetOrder?.productInfo?.picture})` }} />
							<div className="message">
								<div className="name">{targetOrder?.productInfo?.productName}（{targetOrder?.total}份）</div>
								<div className="oid">订单ID：{targetOrder?.orderNumber}</div>
							</div>
							<em className="iconfont icon-close" onClick={() => {
								setTargetOrder(null);
								setTargetOrderFlag(false);
							}} />
						</div>
						<div className="qrcode-list">
							{
								targetOrder?.productItems?.length > 0 && targetOrder.productItems.map((item, idx) => {
									return (
										<div className="qrcode-item" key={idx}>
											<div className="code">
												<QRCode
													id="qrCode"
													value={`http://averify.zjj-travel.yeekin.cn/verification?id=${item?.id}&orderId=${targetOrder?.orderNumber}`}
													size={108} // 二维码的大小
													fgColor="#000000" // 二维码的颜色
													style={{ margin: 'auto' }}
												/>
											</div>
											
											<div className="info">
												<span>ID: {item.hash}</span>
												<span>当天可用，过期可退</span>
											</div>
										</div>
									)
								})
							}
						</div>
					</section>
				</section>
			}
		</Page>
	);
};

export default PageModel;
