import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";

import * as Utils from './common/utils';
import './index.less';
import * as serviceWorker from './serviceWorker';

import PageHome from './pages/home';
import PageProductDetail from './pages/productDetail';
import PageOrderList from './pages/orderList';

const App = () => (
	<Router>
		<div>
			<Route exact path="/" component={PageHome} />
			<Route exact path="/product/:id" component={PageProductDetail} />
			<Route exact path="/orders" component={PageOrderList} />
		</div>
	</Router>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

Utils.user.wxLogin({}, () => window.location.reload());
Utils.sceneHelper.set(); // 场景ID，下单要跟踪使用
// Utils.user.wxsign();
