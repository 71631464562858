/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import {useHistory} from "react-router-dom";

import './index.less';

import * as Utils from '../../common/utils';

const CompModel = props => {
	const history = useHistory();
	const { pageid, showFooter = false, title = '乐游朱家角', style = {} } = props;

	useEffect(() => {
		Utils.setTitle(title);
	}, [ title ]);

	return (
		<div className="page-container" style={style}>
			{ props.children }
			{
				!!showFooter && <div className="page-footer">
					<div className={`pfooter-item${ pageid == 'home' ? ' selected' : ''}`} onClick={() => history.push('/')}>
						<em className="iconfont icon-home" />
						<span>首页</span>
					</div>
					<div className={`pfooter-item${ pageid == 'order' ? ' selected' : ''}`} onClick={() => history.push('/orders')}>
						<em className="iconfont icon-list" />
						<span>订单</span>
					</div>
				</div>
			}
		</div>
	);
};

export default CompModel;
